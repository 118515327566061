import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './afk-guide.scss';
import { AFKItem } from '../../../modules/afk/common/components/afk-item';
import { AFKCharacter } from '../../../modules/afk/common/components/afk-character';
import { Row } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';

const AFKGuidesTips: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page afk-journey afk-guide'}
      game="afk"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/afk-journey/">AFK Journey</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/afk-journey/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Tips and Tricks</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/afk/categories/category_tips.jpg"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>AFK Journey Tips and Tricks</h1>
          <h2>
            What to do and not do in AFK Journey - tips to follow and mistakes
            to avoid!
          </h2>
          <p>
            Last updated: <strong>23/03/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Foreword" />
        <p>
          Before reading this guide,{' '}
          <strong>you should check the Beginner Guide first</strong>. It will
          help you familiarize yourself with the game and its various systems as
          this guide mainly focuses on applying the knowledge and it won't be
          explaining the basic stuff. You can find the Beginner guide here:
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Beginner guide"
            link="/afk-journey/guides/beginner-guide"
            image={
              <StaticImage
                src="../../../images/afk/categories/category_beginner.jpg"
                alt="Beginner guide"
              />
            }
          />
        </Row>
        <p>If you're already read the guide then let's continue!</p>
        <SectionHeader title="General Tips" />
        <h5>Don't feel pressured to spend to progress faster</h5>
        <p>
          AFK Journey is an idle game and{' '}
          <strong>your patience will be rewarded</strong>. Sure, those who spend
          will reach the fabled endgame faster, but with time, you will join
          them there and compete on even ground.
        </p>
        <p>
          You can easily compete with spenders even in rankings and while
          reaching the top spot might not be possible depending on the number of
          'spenders' on your server, you still will be able to reach top 20 or
          even higher in Dream Realm as long as you plan ahead and smartly
          invest your resources.
        </p>
        <p>
          <strong>So don't stress about the leaderboards</strong> and progress
          at your own pace as the game is very free to play friends with tons of
          rewards given from various events or through progression.
        </p>
        <h5>It's a marathon, not a race</h5>
        <p>
          Casually playing for around 70 ~ 80 days should get you to level 240
          and allow you to reach that 1125 Stage and finish the currently
          available story. While you can level past 240 now, the system was just
          added on the game's release, and there's just one content that is
          locked behind clearing the game - Trial of Abyss.
        </p>
        <h5>Rerolling isn't a must</h5>
        <p>
          Like in most idle games, it's not worth to waste your time rerolling
          in AFK Journey. Because:
        </p>
        <ul>
          <li>
            The first 2 pities you reach on the normal banner are 'fixed' when
            it comes to the <AFKItem name="S-Level" /> characters you can
            obtain:
          </li>
          <ul>
            <li>
              The <strong>first pity</strong> (10 pulls) guarantees you a great
              story damage dealer{' '}
              <AFKCharacter mode="inline" slug="cecia" enablePopover /> who also
              can be used with success in other modes early one, but she will
              drop off there in late game (due to being too slow),
            </li>
            <li>
              The <strong>second pity</strong> (20 pulls) guarantees you one of
              the 3 strongest Healers / Supports (equal chance to get each of
              them): <AFKCharacter mode="inline" slug="rowan" enablePopover />{' '}
              <AFKCharacter mode="inline" slug="hewynn" enablePopover />{' '}
              <AFKCharacter
                mode="inline"
                slug="smokey-and-meerky"
                enablePopover
              />
            </li>
          </ul>
        </ul>
        <p>
          Still, if you want to learn more about rerolling, check our dedicated
          guide:
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Reroll guide"
            link="/afk-journey/guides/reroll"
            image={
              <StaticImage
                src="../../../images/afk/categories/category_reroll.jpg"
                alt="Introduction to the game"
              />
            }
          />
        </Row>
        <SectionHeader title="Early Progression" />
        <StaticImage src="../../../images/afk/generic/afk.webp" alt="Guides" />
        <ul>
          <li>
            Progressing in the main story gives you generous rewards via finding
            hidden chests across the map, quest rewards etc,{' '}
            <strong>
              so it's tempting to focus on it first, but it's a trap
            </strong>
            ,
          </li>
          <li>
            <strong>
              Clearing AFK Stages is the first thing you should focus instead
            </strong>{' '}
            as that is how you unlock additional modes and increase your passive
            income of <AFKItem name="Gold" /> and <AFKItem name="Experience" />{' '}
            and <AFKItem name="Essence" />. On top of that, every couple AFK
            stages cleared you will obtain <AFKItem name="Invite Letter" /> and{' '}
            <AFKItem name="Diamonds" /> which will allow you to pull more
            characters, and also Equipment boxes which will make them stronger,
          </li>
          <li>
            Only then jump into the Open World and other modes that unlocked in
            the meantime like Arena, Dream Realm, Labyrinth and Legend Trial.
            The additional rewards you can gain from there will allow you to
            push more AFK Stages and the cycle will repeat till you're stuck
            everywhere for the day,
          </li>
          <li>
            <strong>
              Still, don't be too hasty with Arena and Dream Realm
            </strong>
            . Ideally you should always do your attacks in those modes at the
            end of the day as then you're at your 'strongest' and will achieve
            the best results,
          </li>
          <li>
            <strong>Join a guild as soon as possible</strong> and do the Guild
            Quests/Challenges. Guild currency is used to buy{' '}
            <AFKItem name="Celestial" /> and <AFKItem name="Hypogean" />{' '}
            characters,
          </li>
          <li>
            <AFKItem name="A-Level" /> characters not only aren't useless in
            this game compared to AFK Arena as you can ascend them to the max
            tier and level same as their higher rarity comrades, but some of
            them are actually the best in the game in some modes. So don't be
            afraid of using and investing into them,
          </li>
          <li>
            Wait till the end of the day (or when you're stuck) before using the{' '}
            <strong>Hourglasses</strong> that instantly give you resources worth
            several hours of idling. The further you progress in AFK Stages, the
            more resources you will gain from using them,
          </li>
          <li>
            <strong>Also don't let your AFK Stage idle rewards overcap</strong>.
            When you start the game, the chest will only hold 12 hours worth of
            resources, but as you progress, this will expand to 24h,
          </li>
          <li>
            When it comes to equipment, you don't gear up characters but the
            classes and every character belonging to that class will
            automatically use the equipment in the battle,
          </li>
          <li>
            With the upgrade materials and equipment selection boxes that you
            will obtain as rewards, you want to prioritize upgrading{' '}
            <AFKItem name="Marksman" /> gear because the damage dealers
            belonging to this class are considered the best for both Story
            progression and Dream Realm,
          </li>
          <ul>
            <li>
              Characters like{' '}
              <AFKCharacter mode="inline" slug="cecia" enablePopover />{' '}
              <AFKCharacter mode="inline" slug="odie" enablePopover /> or{' '}
              <AFKCharacter mode="inline" slug="marilee" enablePopover /> will
              be raised on everyone's account,
            </li>
          </ul>
          <li>
            After <AFKItem name="Marksman" /> you should focus on both{' '}
            <AFKItem name="Tank" /> and <AFKItem name="Support" />. Better gear
            will help your Tank to survive longer while the Support gear will
            allow them to use their Ultimate faster,
          </li>
          <li>
            As for the which of the 6 equipment pieces you should focus on
            first, it changes depending on the class. And we have prepared a
            dedicated guide for the equipment priority:
          </li>
        </ul>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Equipment & upgrade priority"
            link="/afk-journey/guides/equipment-and-priority"
            image={
              <StaticImage
                src="../../../images/afk/categories/category_eq.jpg"
                alt="Equipment & upgrade priority"
              />
            }
          />
        </Row>
        <SectionHeader title="Resources" />
        <ul>
          <li>
            Don't do single pulls on the All-Recruit banner! It costs 300{' '}
            <AFKItem name="Diamonds" /> to do a single pull, while a 10-pull
            costs 2700 <AFKItem name="Diamonds" /> as you get a discount on top
            always getting at least 1 <AFKItem name="A-Level" /> character,
          </li>
          <li>
            Also, make sure to setup your Wishlist the moment you unlock it. It
            only takes 30 pulls on the All-Recruit banner so you will already
            unlock the Wishlist during the tutorial,
          </li>
          <li>
            While you will spend majority of your gems on the All-Recruit
            banner, be sure to keep some to buy the weekly/monthly items in
            various shops as they are very good value,
          </li>
          <ul>
            <li>
              Check <Link to="/afk-journey/guides/shop-guide">this guide</Link>{' '}
              for more information about what to buy from each shop.
            </li>
          </ul>
          <li>
            Be very careful with how you spend your{' '}
            <AFKItem name="Temporal Essence" /> and{' '}
            <AFKItem name="Twilight Essence" /> (they are used to upgrade EX
            weapons), as they are currently extremely rare to come by for f2p
            players and the income dries up, especially if you are not reaching
            high positions in the leaderboards,
          </li>
          <li>
            Some characters only need a certain investment in their Exclusive
            Weapon (like +5 or +10 or sometimes +0 is enough) and anything above
            is wasted. We will release a dedicated guide for the investment
            levels soon, but for now be careful with overinvesting all your
            Essences into one character,
          </li>
          <li>
            Characters gain Affinity when you use them in the battle, but you
            can speed up the process by giving them plants you can find all over
            the world or gifts bought from the Friendship store,
          </li>
          <ul>
            <li>
              1 plant gives 100 Affinity and that's enough to obtain 50{' '}
              <AFKItem name="Diamonds" />. To get 50 more{' '}
              <AFKItem name="Diamonds" /> you need to reach 700 Affinity and
              when you reach 1300 Affinity you will also obtain a single{' '}
              <AFKItem name="Invite Letter" />
            </li>
            <li>
              So don't feed all your plants into one character and instead
              spread it out to get more freebies.
            </li>
          </ul>
        </ul>
        <SectionHeader title="Team Building" />
        <StaticImage
          src="../../../images/afk/generic/faction_bonus.webp"
          alt="Guides"
        />
        <ul>
          <li>
            While creating a team, it is important to maximize your faction
            bonus. Whenever more than 2 characters of the same faction are put
            on a team, the entire team’s stats are increased by a certain
            percent. This means you’re incentivized to keep your teams to two
            factions at most, though the <AFKItem name="Celestial" /> and{' '}
            <AFKItem name="Hypogean" /> factions are here to help mitigate the
            issue.
          </li>
          <li>
            <AFKItem name="Celestial" /> and <AFKItem name="Hypogean" /> are
            interesting, as they serve as ‘neutral’ characters that can count
            towards any faction. To make it easier to understand: if you run 4
            characters of the <AFKItem name="Wilders" /> faction, then add
            either a Celestial or Hypogean character, it’ll activate the 5
            character matching faction bonus.
          </li>
          <li>
            <strong>
              Note that it’s not worth it to go for a mono-faction set-up
            </strong>
            . Not only do most factions not have 5 characters that both are good
            and synergize with one another, the extra stats are just not worth
            it when compared to the utility of some of the meta characters.
            Instead, most teams function on a ‘core’,{' '}
            <strong>which is a set of 3 characters of the same faction</strong>{' '}
            that dictate what faction the team will run around. The other two
            unit
          </li>
        </ul>
        <p>For more Team Building tips, check our dedicated guide:</p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Team building (beginner)"
            link="/afk-journey/guides/team-building"
            image={
              <StaticImage
                src="../../../images/afk/categories/category_team.jpg"
                alt="Team building"
              />
            }
          />
        </Row>
        <SectionHeader title="Combat" />
        <StaticImage
          src="../../../images/afk/generic/team_1.webp"
          alt="Guides"
        />
        <ul>
          <li>
            <strong>Auto-play is a trap</strong>. While it makes progressing
            through the game less tedious, if you're stuck, you should switch to
            manual and use the character skills yourself. You can progress way
            further into the game by doing so compared to players who only play
            the game on auto,
          </li>
          <li>
            <strong>Don't use the same formation against every team</strong>.
            Sometimes a slight character placement change can drastically affect
            the outcome of the battle. Is your tank dying to fast? Bring it a
            second tank and put them on the sides of the frontline - now the
            enemy aggro will be split, allowing them to survive longer so your
            backline can use their ultimates,
          </li>
          <li>
            A lot of the stages feature different obstacles (balls, barrels,
            etc.) and sometimes a slight formation adjustment will allow you to
            easily pass a stage that you thought was impossible to clear,
          </li>
          <li>
            <strong>Make use of the traps!</strong> While not every stage has
            them, if they are present, click on them to activate them and make
            enemies life harder,
          </li>
          <li>
            Also don't be afraid to throw less duped characters into your team.
            Since AFK Journey levels your whole box at the same time (based on
            your top 5 characters) and gear is shared between classes, you can
            easily throw someone you never used into your team and they
            instantly can fight at their full potential,
          </li>
          <li>
            This is especially important because of the{' '}
            <strong>Faction Strategies</strong>. A character deals 15% more
            damage when attacking a faction they counter and they take 15% less
            damage from them. So for example if you need to deal with the pesky{' '}
            <AFKCharacter mode="inline" slug="rowan" enablePopover /> hiding in
            the backline, you can use{' '}
            <AFKCharacter mode="inline" slug="silvina" enablePopover /> to
            quickly melt him as <AFKItem name="Graveborn" /> counter{' '}
            <AFKItem name="Lightbearers" />
          </li>
          <li>Here's how the faction relation graph looks like:</li>
        </ul>
        <StaticImage
          src="../../../images/afk/generic/factions.webp"
          alt="Guides"
        />
        <ul>
          <li>
            <li>
              If nothing seems to be working, you can always check the
              formations used by other players who passed the stage.{' '}
              <strong>
                Just search for the small I icon placed in the bottom left
                corner
              </strong>{' '}
              of the Battle button and then press on Records. There you will a
              find of the teams who managed to beat the stage, sorted by those
              with lowest power, how much damage each character did and also
              view the replay of the battle.
            </li>
          </li>
        </ul>
        <StaticImage
          src="../../../images/afk/generic/records.webp"
          alt="Guides"
        />
        <SectionHeader title="Game Modes Tips" />
        <StaticImage
          src="../../../images/afk/generic/beginner_14.webp"
          alt="Guides"
        />
        <h5>Story / World Map</h5>
        <ul>
          <li>
            Kill every enemy on the map and loot all chests for full zone
            completion rewards,
          </li>
          <li>
            Make sure you pick up every plant you find along the way. They are
            used in the Affinity system and can be converted into{' '}
            <AFKItem name="Invite Letter" /> and <AFKItem name="Diamonds" />
          </li>
          <li>
            Make a habit of visiting both Browa Ironhoof and Keith in Holistone
            to see if there are gear/exp that you can claim/buy to make your
            characters stronger.
          </li>
        </ul>
        <h5>AFK Stages</h5>
        <ul>
          <li>
            As we mentioned earlier, the AFK stage you've reached affects your
            passive income gains, so focus on progressing in this mode as a
            priority,
          </li>
          <li>
            Don't use the Instant Rewards feature unless you're stuck. As the
            amount of currency you will obtain is based on your current AFK
            Stage.
          </li>
        </ul>
        <h5>Arena</h5>
        <ul>
          <li>
            In early game, to rank up you need to focus on ensuring victory. Try
            to challenge opponents with lower power only and don't try to
            challenge opponents with similar or higher power than you because
            they'll give more points,
          </li>
          <li>
            Early on also don't bother with trying to go for a meta team - just
            use your strongest PVE characters,
          </li>
          <li>
            Other than the daily free challenge attempts, you can also click on
            the + button at the upper right to get 2 more free challenge
            attempts. We recommend you to used up all the attempts daily,
          </li>
          <li>
            Advancing in rankings not only gives you a generous one-time reward
            but also increase your daily gains through completing tasks in
            Mystic Collection and unlocking new feats. If you're not far from
            advancing a rank, it's worth to spend <AFKItem name="Diamonds" /> on
            challenge attempts since it's very cost-effective as well.
          </li>
        </ul>
        <h5>Legend Trial</h5>
        <ul>
          <li>
            Legend trials has a limit of 20 stages of gap between each factions.
            All faction trials will be opened on Sundays, so that’s the most
            suitable time for you to make up for the missing stages.
          </li>
        </ul>
        <h5>Dream Realm</h5>
        <ul>
          <li>
            From all PVE modes, Dream Realm is the most important early on so
            try to focus on upgrading characters that are great here like{' '}
            <AFKCharacter mode="inline" slug="odie" enablePopover />{' '}
            <AFKCharacter mode="inline" slug="marilee" enablePopover /> or{' '}
            <AFKCharacter mode="inline" slug="kruger" enablePopover />
          </li>
          <li>
            Kill Dream Realm boss daily as it's the best source of{' '}
            <AFKItem name="A-Level" /> character dupes. It's a cycle - investing
            into characters strong here will allow to you upgrade them faster
            which will allow you to do more damage and reach higher ranks,
          </li>
          <li>
            Dream Realm runs have variance even using same characters and same
            positioning. If you can't find a better setup with your current
            characters, try running your best setup again and see if you get
            higher numbers,
          </li>
          <li>
            Unlike other modes, you can't quit/restart the battle here. So if
            you start the battle, your challenge entry will be consumed (and you
            get a limited amount of them every day). So make each attack count,
          </li>
          <li>
            Every Boss requires a slightly different approach, so we suggest
            checking our Dream Realm dedicated guides to learn more about them:
          </li>
        </ul>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Dream Realm - Overview"
            link="/afk-journey/guides/dream-realm"
            image={
              <StaticImage
                src="../../../images/afk/categories/category_dream.jpg"
                alt="Dream Realm - Overview"
              />
            }
          />
          <CategoryCard
            title="King Croaker"
            link="/afk-journey/guides/dream-realm-croaker"
            image={
              <StaticImage
                src="../../../images/afk/categories/category_croaker.jpg"
                alt="King Croaker"
              />
            }
          />
          <CategoryCard
            title="Necrodrakon"
            link="/afk-journey/guides/dream-realm-necrodrakon"
            image={
              <StaticImage
                src="../../../images/afk/categories/category_nekro.jpg"
                alt="Necrodrakon"
              />
            }
          />
          <CategoryCard
            title="Skyclops"
            link="/afk-journey/guides/dream-realm-skyclops"
            image={
              <StaticImage
                src="../../../images/afk/categories/category_skyclops.jpg"
                alt="Skyclops"
              />
            }
          />
          <CategoryCard
            title="Snow Stomper"
            link="/afk-journey/guides/dream-realm-snow-stomper"
            image={
              <StaticImage
                src="../../../images/afk/categories/category_yeti.jpg"
                alt="Snow Stomper"
              />
            }
          />
          /
        </Row>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default AFKGuidesTips;

export const Head: React.FC = () => (
  <Seo
    title="Tips and Tricks | AFK Journey | Prydwen Institute"
    description="What to do and not do in AFK Journey! Tips to follow and mistakes to avoid!"
    game="afk"
  />
);
